const updateDraughtBollard = {
	id: 'updateDraughtBollard',
	selectionType: 'multiple',
	label: 'action.bollardnoray.updatedraughtbollard',
	functionality: 'ACTION_DRAUGHT_BOLLARD',
	checkAvailability: function (registries) {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = [];

		row.isAction = true;
		row.data = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-updateDraughtBollard-bollardnoray-show', row);
	}
};

export default {
	gridactions: [updateDraughtBollard],
	formactions: []
};
