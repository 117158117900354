import { render, staticRenderFns } from "./BollardnorayForm.vue?vue&type=template&id=1053a7ed&scoped=true"
import script from "./BollardnorayForm.vue?vue&type=script&lang=js"
export * from "./BollardnorayForm.vue?vue&type=script&lang=js"
import style0 from "./BollardnorayForm.vue?vue&type=style&index=0&id=1053a7ed&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1053a7ed",
  null
  
)

export default component.exports