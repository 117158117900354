<template>
	<div class="pui-form">
		<bollardnoray-modals :modelName="modelName"></bollardnoray-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<bollardnoray-form-header :modelPk="modelPk"></bollardnoray-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('bollardnoray.portinformation')">
						<v-row dense>
							<v-col cols="4">
								<pui-text-field
									:id="`portname-bollardnoray`"
									v-model="portName"
									:label="$t('bollardnoray.portname')"
									:disabled="formDisabled"
									toplabel
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-text-field
									:id="`berthname-bollardnoray`"
									v-model="berthName"
									:label="$t('bollardnoray.berthname')"
									:disabled="formDisabled"
									toplabel
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-text-field
									:id="`berthcode-bollardnoray`"
									v-model="berthCode"
									:label="$t('bollardnoray.berthcode')"
									:disabled="formDisabled"
									toplabel
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-text-field
									:id="`dockname-bollardnoray`"
									v-model="dockName"
									:label="$t('bollardnoray.dockname')"
									:disabled="formDisabled"
									toplabel
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('bollardnoray.bollardinformation')">
						<v-row dense>
							<v-col cols="4">
								<pui-text-field
									:id="`bolcode-bollardnoray`"
									v-model="model.bolcode"
									:label="$t('bollardnoray.bolcode')"
									:disabled="formDisabled"
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-text-field
									:id="`draught-bollardnoray`"
									v-model="model.draught"
									:label="$t('bollardnoray.draught')"
									:disabled="formDisabled"
									toplabel
								></pui-text-field>
							</v-col>
							<v-col cols="4" class="center-checkbox">
								<pui-checkbox
									:id="`thegeom-bollardnoray`"
									:label="$t('bollardnoray.thegeom')"
									disabled
									v-model="hasGeom"
								></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import bollardnorayActions from './BollardnorayActions';
import bollardnorayModals from './BollardnorayModals.vue';

export default {
	name: 'bollardnoray-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'bollardnoray-modals': bollardnorayModals
	},
	data() {
		return {
			modelName: 'bollardnoray',
			parentModelName: '[PARENT_MODEL_NAME]',
			actions: bollardnorayActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
			this.setParentPk();
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
			}
		}
	},
	computed: {
		portName() {
			let portname = this.$store.getters.getUserPortById(this.model.portid);
			return portname;
		},
		berthCode() {
			let berths = this.$store.getters.getUserBerths;
			let berthSelected = berths.filter((berth) => berth.id == this.model.berthid);
			return berthSelected[0].berthcode;
		},
		berthName() {
			let berths = this.$store.getters.getUserBerths;
			let berthSelected = berths.filter((berth) => berth.id == this.model.berthid);
			return berthSelected[0].name;
		},
		dockName() {
			let berths = this.$store.getters.getUserBerths;
			let berthSelected = berths.filter((berth) => berth.id == this.model.berthid);
			return berthSelected[0].dockname;
		},
		hasGeom() {
			if (this.model.thegeom == null) {
				return false;
			} else {
				return true;
			}
		}
	},
	created() {}
};
</script>

<style lang="postcss" scoped>
.center-checkbox {
	display: flex;
	align-items: center;
}
</style>
